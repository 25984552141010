import Button from "components/Common/Button";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useGetLandingsQuery} from "store/api/landingsApi";

const convertDate = (date: string) => {
  // Convert ISOString to  5/1/25 (MM/DD/YY)
  const d = new Date(date);
  return `${d.getMonth() + 1}/${d.getDate()}/${d
    .getFullYear()
    .toString()
    .slice(-2)}`;
};

function Landings() {
  const {
    data: dataLandings,
    isLoading: isLoadingLandings,
    isError: isErrorLandings,
    refetch,
    // @ts-ignore
  } = useGetLandingsQuery();

  const navigate = useNavigate();

  if (isLoadingLandings) return <div>Loading...</div>;

  if (isErrorLandings)
    return <div>Error loading landings. Please try again.</div>;

  if (!dataLandings || dataLandings.length === 0)
    return <div>No landing pages available.</div>;

  return (
    <div className="flex flex-col gap-8 p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between">
        <h1 className="text-xl font-bold">Landing Pages</h1>
        <div className="flex justify-end">
          <Button
            classes="mr-16"
            variant={"add"}
            onClick={() => {
              navigate("/landing/create");
            }}
          >
            <p className="ml-2">+ Create Landing Page</p>
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
        {dataLandings.map((landing: any) => {
          const {id, page, data} = landing;

          // Skip rendering if `data` is empty
          if (!data) return null;

          // Extract content sections
          const topContent = data?.page?.top_content?.structure;
          const topStyle = data?.page?.top_content?.style;

          return (
            <div
              key={id}
              className="border rounded-lg shadow-md bg-white hover:shadow-lg transition-shadow"
            >
              <div className="mb-4 bg-[#101827] text-white text-center p-3">
                <h2 className="text-lg font-semibold">
                  {page || "Unknown Page"}
                </h2>
                <div className="flex justify-between px-12 mt-2">
                  <p className="text-sm text-gray-600">
                    <strong>Environment:</strong>{" "}
                    <span className="text-gray-400 font-medium">
                      {data.environment || "N/A"}
                    </span>
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>ID:</strong>{" "}
                    <span className="text-gray-400 font-medium">
                      {id || "N/A"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="p-4 pt-0 h-[calc(100%-150px)]">
                {/* Render Top Content */}
                {topContent && (
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col gap-1 text-center">
                      {topContent.logo && (
                        <img
                          src={topContent.logo}
                          alt="Top Content Logo"
                          className="w-auto h-8 object-contain"
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-1 text-center">
                      {topContent.title && (
                        <p className="font-semibold">{topContent.title}</p>
                      )}
                    </div>
                    <div className="flex flex-col gap-1 text-center">
                      {topContent.image && (
                        <img
                          src={topContent.image}
                          alt="Top Content Logo"
                          className="w-auto h-32 object-contain"
                        />
                      )}
                    </div>
                    <div className="flex mt-3 gap-1 text-center justify-between text-sm">
                      <div
                        className="p-1 border border-transparent rounded-sm font-medium"
                        style={{
                          background: topStyle?.expiredAtBackground || "",
                        }}
                      >
                        Expires {convertDate(topContent?.expiredAt)}
                      </div>
                      <div className="p-1 border border-[#452C1E] text-[#452C1E] rounded-sm font-medium">
                        {topContent?.eventType}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <button
                className="w-full bg-[#53bdff75] text-gray-700 text-center p-4 rounded-b-md hover:text-white hover:bg-[#2786bf] font-medium"
                onClick={() => {
                  window.open(`/landing/${id}`, "_blank");
                }}
              >
                Check Landing
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Landings;
