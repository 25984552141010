import React from "react";
import "../../../styles/form.scss";

type Field = {
  type: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  options?: {label: string; value: string}[];
};

export type FormSection = {
  columns: number | "full";
  fields: Field[];
};

type FormProps = {
  form: FormSection[];
  color: string;
};

const FormBuilder: React.FC<FormProps> = ({form, color}) => {
  const renderField = (field: Field, index: number) => {
    switch (field.type) {
      case "input":
      case "email":
        return (
          <div key={index} className="form-field">
            <label>{field.label}</label>
            <input
              className="form-input"
              type={field.type}
              placeholder={field.placeholder}
              required={field.required}
            />
          </div>
        );
      case "textarea":
        return (
          <div key={index} className="form-field">
            <label>{field.label}</label>
            <textarea
              className="form-input"
              placeholder={field.placeholder}
              required={field.required}
            />
          </div>
        );
      case "checkbox":
        return (
          <div key={index} className="form-field">
            <label>
              <input type="checkbox" /> {field.label}
            </label>
          </div>
        );
      case "radio":
        return (
          <div key={index} className="form-field">
            <label>{field.label}</label>
            {field.options?.map((option, idx) => (
              <div key={idx}>
                <label>
                  <input
                    type="radio"
                    name={field.label}
                    value={option.value}
                    required={field.required}
                  />{" "}
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        );
      case "text":
        return (
          <div key={index} className="form-field">
            <label>{field.label}</label>
          </div>
        );
      case "select":
        return (
          <div key={index} className="form-field">
            <label>{field.label}</label>
            <select>
              {field.options?.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form className="flex-1 w-full !max-w-full">
      {form.map((section, sectionIndex) => (
        <div
          key={sectionIndex}
          className={`form-section ${
            section.columns === "full"
              ? "full-width"
              : `columns-${section.columns}`
          }`}
        >
          {section.fields.map((field, fieldIndex) =>
            renderField(field, fieldIndex)
          )}
        </div>
      ))}
      <button
        type="submit"
        style={{
          backgroundColor: color,
        }}
        className="text-white font-medium"
      >
        Submit
      </button>
    </form>
  );
};

export default FormBuilder;
