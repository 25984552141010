import React, {useEffect, useState} from "react";
import Preview from "./Preview";
import {useParams} from "react-router-dom";
import {useGetLandingQuery} from "store/api/landingsApi";

function LandingDetails() {
  const {id} = useParams();

  const {
    data: dataLandingFetch,
    isLoading: isLoadingLandingFetch,
    isError: isErrorLandingFetch,
    refetch,
  } = useGetLandingQuery(id || "");

  const [dataLanding, setDataLanding] = useState({
    page: {
      top_content: {
        structure: {
          logo: "",
          image: "",
          title: "",
          description: "",
          expiredAt: "",
          eventType: "",
          backgroundExpiredAt: "",
          form: [],
        },
        style: {
          background: "",
          color: "",
          expiredAtBackground: "",
          formColor: "",
        },
      },
      mid_content: {
        structure: {
          title: "",
          description: "",
          image: "",
        },
        style: {
          background: "",
          color: "",
        },
      },
      bottom_content: {
        structure: {
          title: "",
          description: "",
          link: {
            label: "",
            url: "",
          },
        },
        style: {
          background: "",
          color: "",
        },
      },
    },
  });
  const handleChange = (path: string[], value: any) => {
    setDataLanding((prev) => {
      const updated = {...prev};
      let obj: any = updated;
      path.forEach((key, index) => {
        if (index === path.length - 1) {
          obj[key] = value;
        } else {
          obj = obj[key];
        }
      });
      return updated;
    });
  };

  useEffect(() => {
    if (dataLandingFetch && !isLoadingLandingFetch && !isErrorLandingFetch) {
      setDataLanding(dataLandingFetch.data);
    }
  }, [dataLandingFetch]);

  return (
    <div className="flex gap-8 p-6 bg-gray-100 min-h-screen">
      {/* CMS Editor */}
      <div className="w-2/5 bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-700">CMS Editor</h2>

        {/* Top Content Editor */}
        <div className="mb-8 border-r-4 border-red-400 pr-4">
          <h3 className="text-xl font-semibold mb-4 text-gray-600">
            Top Content
          </h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Logo URL
              </label>
              <input
                type="text"
                value={dataLanding.page.top_content.structure.logo}
                onChange={(e) =>
                  handleChange(
                    ["page", "top_content", "structure", "logo"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Image URL
              </label>
              <input
                type="text"
                value={dataLanding.page.top_content.structure.image}
                onChange={(e) =>
                  handleChange(
                    ["page", "top_content", "structure", "image"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                value={dataLanding.page.top_content.structure.title}
                onChange={(e) =>
                  handleChange(
                    ["page", "top_content", "structure", "title"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                value={dataLanding.page.top_content.structure.description}
                onChange={(e) =>
                  handleChange(
                    ["page", "top_content", "structure", "description"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Expired At
              </label>
              <input
                type="datetime-local"
                value={
                  dataLanding?.page?.top_content?.structure?.expiredAt
                    ? new Date(
                        dataLanding?.page?.top_content?.structure?.expiredAt
                      )
                        ?.toISOString()
                        ?.slice(0, -1)
                    : undefined
                }
                onChange={(e) =>
                  handleChange(
                    ["page", "top_content", "structure", "expiredAt"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Event Type
              </label>
              <input
                type="text"
                value={dataLanding.page.top_content.structure.eventType}
                onChange={(e) =>
                  handleChange(
                    ["page", "top_content", "structure", "eventType"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Background Color
              </label>
              <input
                type="color"
                value={dataLanding.page.top_content.style.background}
                onChange={(e) =>
                  handleChange(
                    ["page", "top_content", "style", "background"],
                    e.target.value
                  )
                }
                className="mt-1 block px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
        <div className="mb-8 border-r-4 border-green-400 pr-4">
          <h3 className="text-xl font-semibold mb-4 text-gray-600">
            Middle Content
          </h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                value={dataLanding.page.mid_content.structure.title}
                onChange={(e) =>
                  handleChange(
                    ["page", "mid_content", "structure", "title"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <input
                type="text"
                value={dataLanding.page.mid_content.structure.description}
                onChange={(e) =>
                  handleChange(
                    ["page", "mid_content", "structure", "description"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Image URL
              </label>
              <input
                type="text"
                value={dataLanding.page.mid_content.structure.image}
                onChange={(e) =>
                  handleChange(
                    ["page", "mid_content", "structure", "image"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
        <div className="mb-8 border-r-4 border-blue-400 pr-4">
          <h3 className="text-xl font-semibold mb-4 text-gray-600">
            Bottom Content
          </h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                value={dataLanding.page.mid_content.structure.title}
                onChange={(e) =>
                  handleChange(
                    ["page", "mid_content", "structure", "title"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <input
                type="text"
                value={dataLanding.page.mid_content.structure.description}
                onChange={(e) =>
                  handleChange(
                    ["page", "mid_content", "structure", "description"],
                    e.target.value
                  )
                }
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="flex gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Label
                </label>
                <input
                  type="text"
                  value={dataLanding.page.bottom_content.structure.link.label}
                  onChange={(e) =>
                    handleChange(
                      ["page", "bottom_content", "structure", "link", "label"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Link
                </label>
                <input
                  type="text"
                  value={dataLanding.page.bottom_content.structure.link.url}
                  onChange={(e) =>
                    handleChange(
                      ["page", "bottom_content", "structure", "link", "url"],
                      e.target.value
                    )
                  }
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Background Color
              </label>
              <input
                type="color"
                value={dataLanding.page.top_content.style.background}
                onChange={(e) =>
                  handleChange(
                    ["page", "top_content", "style", "background"],
                    e.target.value
                  )
                }
                className="mt-1 block px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>

        {/* Other Sections */}
        {/* Repeat similar blocks for mid_content and bottom_content */}
      </div>

      {/* Preview Panel */}
      <div className="w-3/5 bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-700">Preview</h2>

        <Preview page={dataLanding.page} />
      </div>
    </div>
  );
}

export default LandingDetails;
