import React from "react";
import {NavLink} from "react-router-dom";
import FormBuilder from "./FormBuilder";

export type Page = {
  top_content: ContentSection & {
    structure: {
      logo: string;
      image: string;
      title: string;
      description: string;
      expiredAt: string; // ISO date string
      eventType: string;
      form: any;
    };
    style: {
      background: string;
      color: string;
      expiredAtBackground: string;
      formColor: string;
    };
  };
  mid_content: ContentSection & {
    structure: {
      title: string;
      description: string;
      image: string;
    };
    style: {
      background: string;
      color: string;
    };
  };
  bottom_content: ContentSection & {
    structure: {
      title: string;
      description: string;
    };
    style: {
      background: string;
      color: string;
    };
  };
};

type ContentSection = {
  structure: {
    title: string;
    description: string;
  };
  style: {
    background: string;
    color: string;
  };
};

type FormSection = {
  columns: number | "full"; // Determines layout
  fields: Field[];
};

type Field =
  | InputField
  | TextAreaField
  | CheckboxField
  | RadioField
  | SelectField;

type InputField = {
  type: "input" | "email";
  label: string;
  placeholder: string;
  required?: boolean;
};

type TextAreaField = {
  type: "textarea";
  label: string;
  placeholder: string;
  required?: boolean;
};

type CheckboxField = {
  type: "checkbox";
  label: string;
};

type RadioField = {
  type: "radio";
  label: string;
  options: Option[];
  required?: boolean;
};

type SelectField = {
  type: "select";
  label: string;
  options: Option[];
  required?: boolean;
};

type Option = {
  label: string;
  value: string;
};

const convertDate = (date: string) => {
  // Convert ISOString to  5/1/25 (MM/DD/YY)
  const d = new Date(date);
  return `${d.getMonth() + 1}/${d.getDate()}/${d
    .getFullYear()
    .toString()
    .slice(-2)}`;
};

function Preview({page}: {page: Page}) {
  return (
    <div className="page">
      <header
        className="header"
        style={{
          background: page.top_content.style.background || "#fff",
        }}
      >
        <NavLink to="https://swiggleit.com/" end>
          <img
            src="https://cdn.shopify.com/s/files/1/0759/4190/4662/files/Logo_small_d3b29917-9dd8-4678-b270-b34244d2613b.png?v=1699566286"
            alt="swiggle icon"
            className="w-14"
          />
        </NavLink>
      </header>
      <section className="flex flex-col !p-0">
        <div className="flex w-full justify-center items-center gap-8 pt-8 pb-4 relative flex-col  border-r-4 border-red-400">
          <div
            className="absolute w-full z-0 top-0"
            style={{
              height: "58.3%",
              background: page.top_content.style.background,
            }}
          ></div>
          <img
            className="object-contain !md:w-auto z-10 w-1/2 max-w-[250px]"
            src={page.top_content.structure.logo}
            alt="logo"
          />
          <img
            className="object-contain w-full !md:w-auto max-w-[500px] min-w-full sm:min-w-[500px] z-10 drop-shadow-[0_10px_10px_rgba(0,0,0,0.5)]"
            src={page.top_content.structure.image}
            alt={page.top_content.structure.title}
          />
          <div className="w-full flex justify-between px-4 text-xs max-w-[850px]">
            <div
              className="p-1 border border-transparent rounded-sm font-medium"
              style={{
                background: page.top_content.style.expiredAtBackground,
              }}
            >
              Expires {convertDate(page.top_content.structure.expiredAt)}
            </div>
            <div className="p-1 border border-[#452C1E] text-[#452C1E] rounded-sm font-medium">
              {page.top_content.structure.eventType}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full justify-center px-4 pt-0 sm:pt-6 m-auto max-w-[850px]  border-r-4 border-red-400">
          <p
            className="uppercase !text-sm font-semibold"
            style={{
              color: page.top_content.style.color,
            }}
          >
            {page.top_content.structure.description}
          </p>
          <h1
            className="!m-0 uppercase !text-[32px] font-semibold"
            style={{
              color: page.top_content.style.color,
            }}
          >
            {page.top_content.structure.title}
          </h1>

          <div className="border border-solid rounded-md p-4 mt-4 bg-white">
            <h2 className="!text-2xl font-semibold !mb-4 text-[#452C1E]">
              To Register:
            </h2>
            <FormBuilder
              form={page.top_content.structure.form as FormSection[]}
              color={page.top_content.style.formColor}
            />
          </div>
        </div>
      </section>
      <main className="px-4 max-w-[850px] m-auto flex flex-col gap-3 border-r-4 border-green-400 mt-8">
        <h2 className="text-2xl font-semibold">
          {page.mid_content.structure.title}
        </h2>
        <p>{page.mid_content.structure.description}</p>
        <img
          className="object-contain m-auto"
          src={page.mid_content.structure.image}
          alt={page.mid_content.structure.title}
        />
      </main>
      <footer
        className="p-4 w-full  border-r-4 border-blue-400 mt-8"
        style={{
          background: page.bottom_content.style.background,
        }}
      >
        <div className="p-4 max-w-[850px] m-auto">
          <h3 className="!text-xl font-semibold">
            {page.bottom_content.structure.title}
          </h3>
          <div>{page.bottom_content.structure.description}</div>
        </div>
      </footer>
    </div>
  );
}

export default Preview;
